<template>
  <section class="lg:px-10 lg:py-11 p-4 bg-white rounded-xl">
    <div class="mb-10">
      <p class="text-brand-black font-normal text-3xl font-cooper">Verify email address</p>
      <p class="text-brand-black/40 text-base font-normal grid">
        <span class="">We’ve just sent a 6 digit code to {{ email }}</span>
        <span>Check your email. Enter your email address to continue</span>
        <span>Kindly check your spam folder too, and mark as not spam if there.</span>
      </p>
      <p class="text text-brand-black/75">
        <!-- <vue-countdown v-if="!canResendOtp" :time="secondsToResend" v-slot="{ minutes, seconds }" @end="canResendOtp = true">
          Resend in {{ minutes }}:{{ seconds }}
        </vue-countdown> -->
        <resend-otp  @resend="triggerRequestOtp" />
      </p>
      <!-- v-if="canResendOtp" v-else -->
      <!-- <div class="mt-2">
        <vue-countdown :time="secondsToExpire" v-slot="{ minutes, seconds }" @end="otpExpired">
          OTP code expires in - {{ minutes }}m : {{ seconds }}s
        </vue-countdown>
      </div> -->
    </div>
    <form class="">
      <div style="display: flex; flex-direction: row; justify-content: center">
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator="-"
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
          :placeholder="['*', '*', '*', '*', '*', '*']"
          @on-change="handleOnChange"
          @on-complete="handleOnChange"
        />
      </div>
      <div class="pt-4 space-x-2 md:space-x-4">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
          <VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess" @error="handleError"> </VueRecaptcha>
        </div>
      </div>
    </form>
  </section>
</template>

<script setup>
import Email from '@/assets/icons/email.svg?inline';
import Button from '@/components/Button.vue';
import { errorMessage } from '@/utils/helper';
// import { email } from '@vuelidate/validators';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { VueRecaptcha } from 'vue-recaptcha';
import ResendOtp from '@/components/ResendOtp.vue';

const otpInput = ref('');
const store = useStore();
const router = useRouter();
const email = localStorage.getItem('email');
const captcha = ref('');
const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_KEY);
const otp = ref('');
const canResendOtp = ref(false);
const secondsToExpire = ref(580990);
const secondsToResend = ref(Math.round(1.2 * 100000));

const handleError = () => {};

const handleSuccess = (response) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(response);
  }
  captcha.value = response;
  submit(otp.value);
};

const handleOnChange = (value) => {
  otp.value = value;
  submit(captcha.value);
};

async function otpExpired() {
  alert('Your otp code has expired. Use the resend link to get a new one', 'info');
}

const triggerRequestOtp = async () => {
  canResendOtp.value = false;
  secondsToResend.value = Math.round(secondsToResend.value * 1.7);
  await requestOtp();
  secondsToExpire.value = 580990;
};

const requestOtp = async () => {
  try {
    await store.dispatch('auth/forgetPasswordRequestOtp', { email: await store.getters['global/getFormPart']('email') });
    alert('A new OTP has been sent to your mail', 'success', 4000);
  } catch (error) {
    alert(errorMessage(error) || 'Something went wrong (Please check internent connection)', 'error', 4000);
  }
};

const submit = async (otherValue) => {
  try {
    if (otherValue.length >= 6) {
      const payload = {
        email: await store.getters['global/getFormPart']('email'),
        otp: `${otp.value}`,
        captcha: captcha.value,
      };
      const { data } = await store.dispatch('auth/forgetPasswordVerifyOtp', payload);
      if (process.env.NODE_ENV === 'development') {
        console.log(data.token);
      }
      if (data?.token) {
        await store.commit('auth/setToken', data.token);
        router.push('/new-password');
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.log(error.response.data.errors);
    }
    if (process.env.NODE_ENV === 'development') {
      console.log(error);
    }
    alert(errorMessage(error), 'error');
  }
};
</script>
