<template>
  <section class="">
    <div class="mt-1">
      <a href="#" class="link text-brand-primary/70" full @click="emit('resend')">Resend OTP</a>
    </div>
  </section>
</template>

<script setup>
const emit = defineEmits(['resend']);
</script>
